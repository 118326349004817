import React from "react";
import ApiDetailTemplate from "../components/ApiDetailTemplate";
import ApiData from "../components/ApiData";

const ApiPageTemplate = ({ apiLink }) => {
  const apiItem = ApiData.find((api) => api.link === apiLink);

  if (!apiItem) {
    return <div>API Data not found</div>;
  }

  return (
    <ApiDetailTemplate
      title={apiItem.title}
      description={apiItem.description}
      url={apiItem.component}
      requestExample={apiItem.requestExample}
      responseFormat={apiItem.responseFormat}
      requestParams={apiItem.requestParams}
      responseExample={apiItem.responseExample}
    />
  );
};

export default ApiPageTemplate;
