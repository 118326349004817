import React from "react";
import { useParams } from "react-router-dom";
import ApiPageTemplate from "../components/ApiPageTemplate";
import ApiData from "../components/ApiData";

const ApiPage = () => {
  const { pageName } = useParams();

  const apiItem = ApiData.find((api) => api.link.slice(1) === pageName);

  if (!apiItem) {
    return <div>Page Not Found</div>;
  }
  // 直接返回ApiPageTemplate组件，传入apiLink作为props
  return <ApiPageTemplate apiLink={apiItem.link} />;
};

export default ApiPage;
