import React from "react";
import { Link } from "react-router-dom";

const ApiCard = ({ title, description, link }) => {
  return (
    <Link
      to={link}
      className="font-xwWenKai bg-pink-200/40  hover:bg-pink-200/70 shadow-lg rounded-lg p-6 m-4 w-64 h-29 text-violet-200 hover:scale-105 hover:text-red-50 flex flex-col  transition duration-500 ease-out"
      style={{ textShadow: "1px 1px 2px rgba(0,0,0, 0.8)" }}
    >
      <div>
        <h2 className="text-xl font-bold mb-2">{title}</h2>
        <p>{description}</p>
      </div>
    </Link>
  );
};

export default ApiCard;
