import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ApiCard from "./components/ApiCard";
import ApiData from "./components/ApiData";
import ApiPage from "./components/ApiPage";
import UniverseCanvas from "./components/UniverseCanvas";

const App = () => {
  return (
    <Router>
      <div className="relative bg-custom bg-cover bg-center min-h-screen bg-gray-100 p-4">
        <UniverseCanvas />
        <div className="relative z-10 flex flex-wrap justify-center items-center min-h-screen">
          <Routes>
            <Route
              path="/"
              element={
                <div className="flex flex-wrap justify-center">
                  {ApiData.map((api, index) => (
                    <ApiCard
                      key={index}
                      title={api.title}
                      description={api.description}
                      link={api.link}
                    />
                  ))}
                </div>
              }
            />
            <Route path="/:pageName" element={<ApiPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
