import React, { useEffect, useRef } from "react";

const UniverseCanvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let width, height, stars, starCount;
    const starScale = 0.03; // 调整普通星星的移动速度，使其更慢
    const cometScale = 0.05; // 调整彗星的移动速度，使其更快
    const colors = {
      star: "226,225,142",
      comet: "226,225,224",
    };

    function init() {
      resize();
      window.addEventListener("resize", resize);
      stars = [];
      starCount = Math.round(width * 0.4); // 增加星星数量
      for (let i = 0; i < starCount; i++) {
        stars.push(new Star());
      }
      animate();
    }

    function resize() {
      width = window.innerWidth;
      height = window.innerHeight;
      canvas.width = width;
      canvas.height = height;
    }

    function animate() {
      context.clearRect(0, 0, width, height);
      stars.forEach((star) => star.update());
      requestAnimationFrame(animate);
    }

    function Star() {
      this.reset();
    }

    Star.prototype.reset = function () {
      this.comet = Math.random() < 0.05; // 5% 概率为流星
      if (this.comet) {
        // 彗星起始位置在左上角
        this.x = Math.random() * width * 0.7;
        this.y = Math.random() * height * 0.3;
        this.dx = Math.random() * cometScale * 10 + 1;
        this.dy = Math.random() * cometScale * 10 + 1;
      } else {
        // 普通星星均匀分布
        this.x = Math.random() * width;
        this.y = Math.random() * height;
        this.dx = (Math.random() - 0.5) * starScale * 10;
        this.dy = (Math.random() - 0.5) * starScale * 10;
      }
      this.r = Math.random() * 1.5 + 1.1;
      this.opacity = 0;
      this.opacityTresh = Math.random() * 0.6 + 0.4;
      this.do = Math.random() * 0.002 + 0.001;
    };

    Star.prototype.update = function () {
      if (this.opacity < this.opacityTresh) {
        this.opacity += this.do;
      } else if (this.opacity > 0) {
        this.opacity -= this.do / 2;
      }

      if (this.x < 0 || this.x > width || this.y < 0 || this.y > height) {
        this.reset();
      }

      this.x += this.dx;
      this.y += this.dy;
      this.draw();
    };

    Star.prototype.draw = function () {
      context.beginPath();
      if (this.comet) {
        context.fillStyle = `rgba(${colors.comet},${this.opacity})`;
        context.arc(this.x, this.y, 1.5, 0, 2 * Math.PI);
        for (let i = 0; i < 30; i++) {
          context.fillStyle = `rgba(${colors.comet},${
            this.opacity - (this.opacity / 20) * i
          })`;
          context.fillRect(
            this.x - (this.dx / 4) * i,
            this.y - (this.dy / 4) * i - 2,
            2,
            2
          );
        }
      } else {
        context.fillStyle = `rgba(${colors.star},${this.opacity})`;
        context.fillRect(this.x, this.y, this.r, this.r);
      }
      context.closePath();
      context.fill();
    };

    init();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      id="universe"
      className="absolute top-0 left-0 w-full h-full"
    />
  );
};

export default UniverseCanvas;
