const ApiData = [
  {
    title: "今日运势",
    description: "获取每日运势和幸运指数",
    link: "/jrys",
    component: "jrys",
    requestParams: [
      { name: "lucky", description: "good是四颗星以上的，bad是剩下的" },
    ],
    requestExample: ["?lucky=good", "?lucky=bad"],
    responseFormat: "JSON",
    responseExample: `{
  "fortuneSummary": "大吉+官运",
  "luckyStar": "★★★★★★☆",
  "signText": "旭日升天，名显四方，渐次进展，终成大业",
  "unsignText": "旭日东昇势壮富，贯彻大业万事胜，终至荣达功名显，猛虎添翼势声强。具有此数之人，男多勇敢果断，女多貌若天仙，有风流不羁不顾时局大体倾向，若修养不够或抑制力不强，往往因桃色事件惹来麻烦。"
}`,
  },
  {
    title: "随机一言",
    description: "hitokoto",
    link: "/hitokoto",
    component: "hitokoto",
    requestParams: [
      { name: "type", description: "json, txt" },
      {
        name: "sentence",
        description:
          "| a 动画 | b 漫画 | c 游戏 | d 文学 | e 原创 | f 网络 | g 其他 | h 影视 | i 诗词 | j 网易云 | k 哲学 | l 抖机灵 ",
      },
    ],
    requestExample: ["?type=txt", "?sentence=j", "?sentence=k&type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `"如果我不握着剑，我就无法保护你，如果我总握着剑，我就无法抱紧你。"`,
  },
  {
    title: "答案之书",
    description: "随机答案之书",
    link: "/answerbook",
    component: "answerbook",
    requestParams: [{ name: "type", description: "json, txt" }],
    requestExample: ["?type=json", "?type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `{"answer":"你肯定会获得支持"}`,
  },
  {
    title: "随机猫猫狗狗",
    description: "随机猫狗图片",
    link: "/catdog",
    component: "catdog",
    requestParams: [{ name: "type", description: "cat, dog" }],
    requestExample: ["?type=cat", "?type=dog"],
    responseFormat: "JSON",
    responseExample: `{
    "url": "https://cdn2.thecatapi.com/images/Pqtwt4FCq.jpg",
    "breed": "Persian"
  }`,
  },
  {
    title: "发疯",
    description: "死了都要爱",
    link: "/fafeng",
    component: "fafeng",
    requestParams: [{ name: "type", description: "json, txt" }],
    requestExample: ["?type=json", "?type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `{"fafeng":"{target_name}睡不着要我陪着她，直到哄着她睡着了为止，没办法，为了让{target_name}安心睡觉，我只能待会再来水群了"}`,
  },
  {
    title: "海龟汤",
    description: "智商测试？",
    link: "/hgt",
    component: "hgt",
    requestParams: [{ name: "type", description: "json, txt" }],
    requestExample: ["?type=json", "?type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `{"hgt":"小宁最近很喜欢剪指甲，走近她身边常会听到「喀喀」的声音。　　「这指甲剪是我男朋友送我的喔，他说我可以把剪指甲当作剪掉不愉快的事，剪完了心情就会变好了。」她甜蜜的笑着。　　可不久后，他提出了分手。她开始以泪洗面，哭累了，就拿出他送的指甲剪，一点一点开始剪指甲，「我要把他忘掉，剪掉了就忘了，我要忘记他…」之后，她就没来上班了。　　几天后，小远上洗手间时，突然听到隔壁的厕所不断传来剪指甲的「喀喀」声。　　「小宁，是你吗？」几声询问都没有响应后，小远好奇的蹲下了身子，想看看隔壁是谁；之后，只知道她发狂尖叫冲出了厕所。　　工友开了门，在惊恐的众人眼前，只看到一只拿着指甲剪的手，以及满地指甲剪剪下，一人份的碎肉"}`,
  },
  {
    title: "疯狂星期四",
    description: "vivo50",
    link: "/crazythursday",
    component: "crazythursday",
    requestParams: [{ name: "type", description: "json, txt" }],
    requestExample: ["?type=json", "?type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `{"crazythursday":"我本是夜上海的一名贫穷又自强的黄包车夫，看遍了这个城市的繁华和热闹，也体验了这个社会的冷漠与无情。直到那一天，我的车上坐上了一位穿着四季歌的千金小姐，我感到了我平静已久的内心再一次颤动，我知道不应该，但我却还是爱上了。我知道我的身份地位配不上她，只求有个好心人给我39.9，让我买一个疯狂星期四热辣鸡桶，带给她舔狗的关怀。"}`,
  },
  {
    title: "emoji合成",
    description: "合成emoji",
    link: "/emoji",
    component: "emoji",
    requestParams: [
      { name: "tpye", description: "json, pic" },
      { name: "emoji1", description: "第一个emoji" },
      { name: "emoji2", description: "第二个emoji" },
    ],
    requestExample: ["?emoji1=🐱&emoji2=🌹", "?emoji1=🐱&emoji2=🌹&type=pic"],
    responseFormat: "JSON/PIC",
    responseExample: `{
"url": "https://www.gstatic.com/android/keyboard/emojikitchen/20230127/u1f339/u1f339_u1f431.png"
}`,
  },
  {
    title: "颜文字表情",
    description: "随机颜文字表情",
    link: "/emoticon",
    component: "emoticon",
    requestParams: [{ name: "type", description: "json, txt" }],
    requestExample: ["?type=json", "?type=txt"],
    responseFormat: "JSON/TXT",
    responseExample: `{ "emoticon": "ฅ˙Ⱉ˙ฅ" }`,
  },
  {
    title: "随机咖波图",
    description: "猫猫虫咖波",
    link: "/capoo",
    component: "capoo",
    requestParams: [{ name: "type", description: "json, pic" }],
    requestExample: ["?type=json", "?type=pic"],
    responseFormat: "JSON/PIC",
    responseExample: `{ image_url": "https://git.acwing.com/XT/capoo/-/raw/main/gif/71.gif" }`,
  },
  {
    title: "随机龙图",
    description: "柠的檬青",
    link: "/long",
    component: "long",
    requestParams: [{ name: "type", description: "json, pic" }],
    requestExample: ["?type=json", "?type=pic"],
    responseFormat: "JSON/PIC",
    responseExample: `{ "image_url": "https://git.acwing.com/XT/long/-/raw/main/2/303.jpg" }`,
  },
  {
    title: "随机坤图",
    description: "油饼食不食",
    link: "/ikun",
    component: "ikun",
    requestParams: [{ name: "type", description: "json, pic" }],
    requestExample: ["?type=json", "?type=pic"],
    responseFormat: "JSON/PIC",
    responseExample: `{ "image_url": "https://git.acwing.com/XT/ikun/-/raw/main/2/303.jpg" }`,
  },
  {
    title: "地理位置信息",
    description: "根据ip获取地理位置",
    link: "/location",
    component: "location",
    requestParams: [
      { name: "ip", description: "可不填 填了就是返回你输入的这个ip" },
    ],
    requestExample: ["?ip=*.*.*.*"],
    responseFormat: "JSON",
    responseExample: `{
  "ip": "*.*.*.*",
  "city": "Sydney",
  "state_prov": "New South Wales",
  "country_name": "Australia",
  "latitude": "***",
  "longitude": "***",
  "isp": "Urbanest Pty Limited",
  "organization": "Broadband Solutions Pty Ltd"
}`,
  },
  {
    title: "汇率查询",
    description: "查询汇率以及转化",
    link: "/exchange_rate",
    component: "exchange_rate",
    requestParams: [
      { name: "currency1", description: "需要查询的货币" },
      { name: "currency2", description: "需要转化的货币（可以不填）" },
    ],
    requestExample: ["?currency1=AUD", "?currency1=AUD&currency2=CNY"],
    responseFormat: "JSON",
    responseExample: `{
  "currency1": "AUD",
  "currency2": "CNY",
  "exchange_rate": ***,
  "currency1_name": "澳币",
  "currency2_name": "人民币"
}`,
  },
  {
    title: "热搜",
    description: "获取实时热搜",
    link: "/hot",
    component: "hot",
    requestParams: [{ name: "from", description: "zhihu, weibo" }],
    requestExample: ["?from=zhihu", "?from=weibo"],
    responseFormat: "JSON",
    responseExample: `{
  "success": true,
  "title": "知乎热榜",
  "subtitle": "热度",
  "update_time": "2024-07-14 20:23:17",
  "data": [ 略 ]
}`,
  },
  // 其他API卡片数据...
];

export default ApiData;
